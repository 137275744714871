import type * as React from 'react';

import type { NextRouter } from 'next/router';
import { useCallback, useEffect } from 'react';

export const preventFormDataLossMsg = 'Route change aborted to prevent form data loss.';

export function usePreventFormDataLoss({
  isDirty,
  router,
  isModalOpen,
  setIsModalOpen,
  setUrlToRedirect,
}: {
  isDirty: boolean;
  router: NextRouter;
  isModalOpen: boolean;
  setIsModalOpen: React.Dispatch<React.SetStateAction<boolean>>;
  setUrlToRedirect: React.Dispatch<React.SetStateAction<string | undefined>>;
}) {
  const nativeNavigationChange = useCallback(
    (event: BeforeUnloadEvent) => {
      if (!isDirty) return;
      event.preventDefault();
    },
    [isDirty],
  );

  const nextNavigationChange = useCallback(
    (url: string, { shallow }: { shallow: boolean }) => {
      if (!isDirty || shallow) return;
      setUrlToRedirect(url);
      setIsModalOpen(true);
      router.events.emit('routeChangeError');
      throw preventFormDataLossMsg;
    },
    [isDirty, router.events, setIsModalOpen, setUrlToRedirect],
  );

  useEffect(() => {
    if (!isModalOpen) window.addEventListener('beforeunload', nativeNavigationChange);
    router.events.on('routeChangeStart', nextNavigationChange);

    return () => {
      window.removeEventListener('beforeunload', nativeNavigationChange);
      router.events.off('routeChangeStart', nextNavigationChange);
    };
  }, [isModalOpen, router.events, nativeNavigationChange, nextNavigationChange]);
}
