import type { LoggerOptions } from 'pino';

export const pinoLoggerConfig = (): LoggerOptions => {
  const level = process.env.LOG_LEVEL ?? 'info';
  const env = process.env.NODE_ENV ?? 'development';
  const enablePrettyLog = process.env.LOG_PRETTY !== 'false';

  const pinoPretty = env === 'development' &&
    enablePrettyLog && {
      target: 'pino-pretty',
      options: {
        colorize: true,
        ignore: 'pid,hostname,context,id',
        singleLine: false,
      },
    };
  const transport = pinoPretty || undefined;
  return {
    level: level,
    formatters: {
      level: (label) => {
        return {
          level: label,
        };
      },
    },
    serializers: {
      res(response) {
        return {
          id: response.config.headers['x-request-id'],
          url: response.config.url,
          statusCode: response.status,
          size: response.headers['content-length'],
        };
      },
      err(error) {
        return {
          id: error.config.headers['x-request-id'],
          name: error.name,
          code: error.code,
          status: error.status,
          message: error.message,
          data: error.response.data,
          stack: error.stack,
        };
      },
    },
    redact: {
      paths: ['*.token', '*.clientSecret', '*.password'],
      remove: true,
    },
    transport: transport,
  };
};
