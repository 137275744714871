import { pino, type Logger } from 'pino';
import { pinoLoggerConfig } from './pino-logger-config';

const isServer = typeof window === 'undefined';

const serverLogger: Logger = pino({
  ...pinoLoggerConfig(),
});

const sendLogToServer = async (level: 'info' | 'error', message: string, context = {}) => {
  // Direct server-side logging
  if (isServer) {
    serverLogger[level]({
      msg: `server-side ${message}`,
      ...context,
      version: process.env.npm_package_version,
    });
    return;
  }
};

const logger = {
  info: (message: string, context = {}) => sendLogToServer('info', message, context),
  error: (message: string, context = {}) => sendLogToServer('error', message, context),
};

export default logger;
