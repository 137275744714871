import { DealerRole } from '@/generated/typing';
import type { RolesMap } from './user-access';
import { Section, Feature } from './user-access';

const dealerAccess: RolesMap = {
  [DealerRole.Admin]: {
    [Section.CHILDREN]: true,
    [Section.DASHBOARD]: true,
    [Section.DEALERS]: true,
    [Section.CLIENTS]: true,
    [Section.STORES]: true,
    [Section.STORE]: true,
    [Section.PRODUCTS]: true,
    [Section.PROVIDERS]: true,
    [Section.CONTRACTS]: true,
    [Section.ADMINISTRATION]: true,
    [Section.BILLING_INFORMATIONS]: true,
    [Section.SUBSCRIPTIONS]: true,
    [Section.SUBSCRIBE]: true,
    [Section.COMPANY]: true,
    [Section.ACCOUNT_SETTINGS]: true,
    [Section.API]: true,
    [Section.ACCOUNT]: true,
    [Section.ACCOUNT_UPDATE]: true,
    [Section.STORE_INVOICES]: true,
    [Section.STORE_CLAIMS]: true,
    [Feature.DELETE_DEALER_FROM_STORE]: true,
    [Feature.VOID_SUBSCRIPTION]: true,
    [Feature.REFUND_SUBSCRIPTION]: true,
    [Feature.IBAN_BILLING_INFORMATIONS]: true,
    [Feature.CANCEL_SUBSCRIPTION_CONTRACT]: true,
    [Section.STORE_IMPORT_HISTORY]: true,
    [Section.SUBSCRIPTION_PROCEDURES]: true,
    [Section.WEBHOOKS]: true,
    [Feature.EXPORT_SUBSCRIPTIONS]: true,
    [Feature.UPDATE_CUSTOMER]: true,
    [Feature.UPDATE_BENEFICIARIES]: true,
    [Feature.GENERATE_MEMBERSHIP_AGREEMENT]: true,
    [Feature.SEND_DOCUMENT]: true,
  },
  [DealerRole.Seller]: {
    [Section.CLIENTS]: true,
    [Section.STORES]: true,
    [Section.STORE]: true,
    [Section.PRODUCTS]: true,
    [Section.PROVIDERS]: true,
    [Section.SUBSCRIPTIONS]: true,
    [Section.ACCOUNT_SETTINGS]: true,
    [Section.ACCOUNT]: true,
    [Section.STORE_IMPORT_HISTORY]: true,
    [Feature.VOID_SUBSCRIPTION]: true,
    [Section.SUBSCRIPTION_PROCEDURES]: true,
    [Section.SUBSCRIBE]: true,
    [Feature.UPDATE_BENEFICIARIES]: true,
    [Feature.GENERATE_MEMBERSHIP_AGREEMENT]: true,
    [Feature.SEND_DOCUMENT]: true,
    [Feature.CANCEL_SUBSCRIPTION_CONTRACT]: true,
    [Section.STORE_CLAIMS]: true,
  },
  [DealerRole.User]: {
    [Section.DASHBOARD]: true,
    [Section.SUBSCRIPTIONS]: true,
    [Section.CLIENTS]: true,
    [Section.STORE_IMPORT_HISTORY]: true,
    [Section.SUBSCRIPTION_PROCEDURES]: true,
  },
};

export const dealerHasAccessTo = (
  role: DealerRole,
  item: Section | Feature,
  specificCondition: boolean = true,
): boolean => {
  if (specificCondition !== undefined && !specificCondition) {
    return false;
  }

  return !!dealerAccess[role]?.[item];
};
