import { Api } from '@/generated/typing';
import getConfig from 'next/config';
import logger from '@/utils/logger/server-logger';
const { publicRuntimeConfig } = getConfig();

const api = new Api({
  baseURL: publicRuntimeConfig.apiBaseUrl,
});

api.instance.interceptors.response.use(
  (res) => {
    logger.info(`request completed`, {
      res,
    });
    return res;
  },
  (err) => {
    logger.error('request failed', {
      err,
    });
    return Promise.reject(err);
  },
);

export default api;
