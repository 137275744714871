import type { HydrateProps } from '@tanstack/react-query';
import { Hydrate, QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { SessionProvider } from 'next-auth/react';
import type { Session } from 'next-auth';
import type { AppContext, AppProps } from 'next/app';
import App from 'next/app';

import './../core/i18n';
import '@/assets/fonts.css';
import '@/assets/global.css';
import { AuthProvider } from '@/contexts/auth-context';
import { UserProvider } from '@/contexts/user-context';
import type { ReactElement, ReactNode } from 'react';
import { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import type { NextPage } from 'next';
import Head from 'next/head';
import { ThemeProvider } from '@/chakra/ThemeContext';
import { RouteQueryToast } from '@/components/RouteQueryToast';
import TagManager from 'react-gtm-module';
import { useDevEnv } from '@/utils/dev-env';
import { ConfirmModalProvider } from '@/hooks/useCreateConfirmModal';
import { AdminProvider } from '@/contexts/admin-context';
import { DealerProvider } from '@/contexts/dealer-context';
import { ProviderAccountProvider } from '@/contexts/provider-account-context';
import { OpenAPI } from '@/generated-hooks/requests';
import getConfig from 'next/config';
import '@neat-eu/design-system/styles/variables.css';
import '@neat-eu/design-system/styles/reset.css';
// TODO (BRUJ 07/11/2024): All style is loading on first render
import '@neat-eu/design-system/styles/default.css';
import type { AnchorProps } from '@neat-eu/design-system';
import { DesignSystemAnchorContext, ToastProvider } from '@neat-eu/design-system';
import NextLink from 'next/link';

export type NextPageWithLayout = NextPage & {
  getLayout?: (page: ReactElement) => ReactNode;
};

type AppPropsWithLayout<T = {}> = AppProps<T> & {
  Component: NextPageWithLayout;
};

const { publicRuntimeConfig } = getConfig();
OpenAPI.BASE = publicRuntimeConfig.apiBaseUrl;
function NeatApp({
  Component,
  pageProps,
}: AppPropsWithLayout<{ session: Session; hydrateProps?: HydrateProps }>) {
  const { publicRuntimeConfig } = getConfig();
  const { t } = useTranslation(['pageTitle']);
  const [queryClient] = useState(() => new QueryClient());
  const { session, hydrateProps, ...props } = pageProps;

  const getLayout = Component.getLayout ?? ((page) => page);

  useEffect(() => {
    TagManager.initialize({
      gtmId: publicRuntimeConfig.gtmId,
      auth: publicRuntimeConfig.gtmAuth,
      preview: publicRuntimeConfig.gtmPreview,
    });
  }, [publicRuntimeConfig.gtmAuth, publicRuntimeConfig.gtmId, publicRuntimeConfig.gtmPreview]);

  const devEnv = useDevEnv();

  const favicon = useMemo(() => {
    if (devEnv === 'local') {
      return '/favicon-local.ico';
    }
    if (devEnv === 'staging') {
      return '/favicon-staging.ico';
    }
    return '/favicon.ico';
  }, [devEnv]);

  return (
    <>
      <Head>
        <link rel="icon" href={favicon} sizes="any" />
        <title>{t('pageTitle:default')}</title>
      </Head>
      <QueryClientProvider client={queryClient}>
        <Hydrate {...hydrateProps}>
          <SessionProvider session={session}>
            <AuthProvider>
              <AdminProvider>
                <DealerProvider>
                  <ProviderAccountProvider>
                    <UserProvider>
                      <DesignSystemAnchorContext.Provider
                        value={{ Anchor: (props: AnchorProps) => <NextLink {...props} /> }}
                      >
                        <ThemeProvider>
                          <ToastProvider>
                            <RouteQueryToast>
                              <ConfirmModalProvider>
                                {getLayout(<Component {...props} />)}
                              </ConfirmModalProvider>
                            </RouteQueryToast>
                          </ToastProvider>
                        </ThemeProvider>
                      </DesignSystemAnchorContext.Provider>
                    </UserProvider>
                  </ProviderAccountProvider>
                </DealerProvider>
              </AdminProvider>
            </AuthProvider>
          </SessionProvider>
        </Hydrate>
      </QueryClientProvider>
    </>
  );
}

NeatApp.getInitialProps = async (context: AppContext) => {
  const ctx = await App.getInitialProps(context);
  return { ...ctx };
};

export default NeatApp;
